import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import SubFooter from "../components/sub-page/sub-footer"
import NetworkBackground from "../images/webdev.jpg"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Responsive from "../images/webdev/responsive.jpeg"
import Hosting from "../images/webdev/hosting.jpeg"
import Dev from "../images/webdev/dev.jpeg"

const WebDevelopment = () => (
  <Layout>
    <Seo title="Web Development" />
    <div
      className="rounded-xl shadow-xl py-24 mx-auto px-6 sm:py-12 md:py-36 md:px-10"
      style={{
        backgroundImage: `url(${NetworkBackground})`,
        backgroundSize: `cover`,
      }}
    >
      <div className="sm:text-left lg:text-left">
        <h1 className="text-4xl tracking-tight font-heading font-extrabold text-white sm:text-5xl md:text-6xl lg:text-8xl">
          Web <span className="text-sun">Development</span>
        </h1>
        <p className="mt-3 text-base font-body text-white sm:mt-5 sm:text-lg sm:max-w-2xl sm:mx-0 md:mt-5 md:text-xl lg:mx-0">
          Innovative solutions and cutting edge design to launch your web
          presense into the future.
        </p>
      </div>
    </div>
    <div className="mt-16 mb-24 text-white flex justify-center flex-wrap gap-6">
      <div className="h-full group flex flex-col justify-end bg-cover bg-center bg-no-repeat rounded-lg shadow-xl">
        <AnchorLink to="/web-development#responsive" className="h-full">
          <div
            className="transition flex justify-center items-center ease-in-out duration-500 bg-insight bg-opacity-75 p-8 rounded-lg group-hover:bg-opacity-100"
            style={{ width: "250px", minHeight: "200px" }}
          >
            <h2 className="text-2xl text-center font-heading font-bold pb-2">
              Web Design
            </h2>
          </div>
        </AnchorLink>
      </div>
      <div className="h-full group flex flex-col justify-end bg-cover bg-center bg-no-repeat rounded-lg shadow-xl">
        <AnchorLink to="/web-development#dev" className="h-full">
          <div
            className="transition flex justify-center items-center ease-in-out duration-500 bg-insight bg-opacity-75 p-8 rounded-lg group-hover:bg-opacity-100"
            style={{ width: "250px", minHeight: "200px" }}
          >
            <h2 className="text-2xl text-center font-heading font-bold pb-2">
              Web Development
            </h2>
          </div>
        </AnchorLink>
      </div>
      <div className="h-full group flex flex-col justify-end bg-cover bg-center bg-no-repeat rounded-lg shadow-xl">
        <AnchorLink to="/web-development#hosting" className="h-full">
          <div
            className="transition flex justify-center items-center ease-in-out duration-500 bg-insight bg-opacity-75 p-8 rounded-lg group-hover:bg-opacity-100"
            style={{ width: "250px", minHeight: "200px" }}
          >
            <h2 className="text-2xl text-center font-heading font-bold pb-2">
              Hosting
            </h2>
          </div>
        </AnchorLink>
      </div>
    </div>
    <div className="my-24 mx-auto px-6 grid grid-cols-1 gap-6 md:gap-x-6 md:gap-y-10 md:grid-cols-3 md:px-10">
      <div
        id="responsive"
        className=" col-span-1 rounded-xl border-4 border-gray-100 py-6 mx-auto px-6 transition duration-300 ease-in-out hover:bg-gray-100 md:px-10"
      >
        <div>
          <div className="flex justify-between items-center">
            <div>
              <h2 className="text-4xl pb-8">Responsive Web Design</h2>
            </div>
          </div>
          <div>
            <p className="text-xl max-w-6xl">
              More than ever, customers are access your site from thier mobile
              devices. This means you need a web site that works great and looks
              beautiful on every device. At i360 we use a mobile first design
              approach to ensure ever page on your site is perfectly optimized
              for ever customer's device.
            </p>
            <p className="text-xl max-w-6xl pt-4">
              Things We Focus On:{" "}
              <strong>
                Conversions, Intuitive Navigation, Search Engine Optimization,
              </strong>{" "}
              and <strong>Mobile Responsiveness</strong>
            </p>
          </div>
        </div>
      </div>
      <div className="rounded-xl mx-auto transition duration-300 ease-in-out shadow-xl flex justify-center overflow-hidden md:col-span-2">
        <img className="rounded-xl object-fill" src={Responsive} />
      </div>
      <div id="dev" className="rounded-xl mx-auto transition duration-300 ease-in-out shadow-xl flex justify-center overflow-hidden md:col-span-1">
        <img className="rounded-xl object-cover" src={Dev} />
      </div>
      <div
        className=" md:col-start-2 md:col-span-2 rounded-xl border-4 border-gray-100 py-6 mx-auto px-6 transition duration-300 ease-in-out hover:bg-gray-100 md:px-10"
      >
        <div>
          <div className="flex justify-between items-center">
            <div>
              <h2 className="text-4xl pb-8">Web Development</h2>
            </div>
          </div>
          <div>
            <p className="text-xl max-w-6xl">
              When building your web solution, i360 evalutes a number of cutting
              edge technologies to determine what's right for you. We have years
              of experince working with many popular content management systems
              as well as emerging web technologies. Our goal is build the best,
              most reliable and fastest site for you.
            </p>
            <p className="text-xl max-w-6xl pt-4">
              Tech We Work With:{" "}
              <strong>
                Wordpress, Joomla, Drupal, JAM Stack, GatsbyJS, VueJS
              </strong>{" "}
              and <strong>many more</strong>
            </p>
          </div>
        </div>
      </div>
      <div
        id="hosting"
        className=" md:col-start-1 md:col-span-2 rounded-xl border-4 border-gray-100 py-6 mx-auto px-6  transition duration-300 ease-in-out hover:bg-gray-100 md:px-10"
      >
        <div>
          <div className="flex justify-between items-center">
            <div>
              <h2 className="text-4xl pb-8">Blazing Fast and Secure Hosting</h2>
            </div>
          </div>
          <div>
            <p className="text-xl max-w-6xl">
              i360 provides top tier hosting to ensure your website loads as
              fast as possible. On top of that we use state of the art
              encryption and other security technologies to garuntee the safety
              of your site.
            </p>
            <p className="text-xl max-w-6xl pt-4">
              Things We Focus On:{" "}
              <strong>
                TSL/SSL Encryption, Content Delivery Network, Scalability
              </strong>{" "}
              and <strong>Reliability</strong>
            </p>
          </div>
        </div>
      </div>
      <div className="rounded-xl mx-auto transition duration-300 ease-in-out shadow-xl flex justify-center overflow-hidden md:col-span-1">
        <img className="rounded-xl object-cover" src={Hosting} />
      </div>
    </div>
    <SubFooter />
  </Layout>
)

export default WebDevelopment
